.headercomponent {
  justify-content: flex-end;
  align-items: center;
  padding: 30px 10%;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  padding-right: 10%;
  padding-left: 10%;
  background-color: #343a40;
}
